import React from "react";
import { Routes, Route, Navigate } from "react-router";
import { WrongPath } from "./WrongPath";
import { LoginPage } from "./Components/Login/LoginPage";
import { CheckAuth } from "./auth/CheckAuth";
import { LoginKeeper } from "./auth/LoginKeeper";
import UsersListing from "./Components/Users/UsersListing";
import { Backdrop } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import { useSelector } from "react-redux";
import AddTheme from "./Components/AddTheme/AddTheme";
import Eventmanagement from "./Components/EventManagement/Eventmanagement";
import DropdownWithEditDelete from "./Components/testing"
import HomeAutomation from "./Components/Users/HomeAutomation";
import Subscribers from "./Components/Users/Subscribers";
import ViewUsers from "./Components/View/ViewUsers";
import ChannelManagement from "./Components/ChannelManagement/ChannelManagement";
import ChannelView from "./Components/View/ChannelView";

export const Router = () => {
  let loader = useSelector(e => e.loader)
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <Triangle
          height="80"
          width="80"
          color="black"
          ariaLabel="triangle-loading"
          visible={loader}
        />
      </Backdrop>

      
      <Routes>
        <Route element={<LoginKeeper />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route element={<CheckAuth />}>
          {/* <Route exact path="/qrcode" element={<Qrcode />} /> */}
          <Route exact path="/subscribers" element={<Subscribers />} />
          <Route path="/" element={<Navigate to="/userlisting" />} />
          <Route exact path="/userlisting" element={<UsersListing />} />
          <Route exact path="/channel" element={<ChannelManagement />} />
          <Route exact path="/addTheme" element={<AddTheme />} />
          <Route exact path="/eventmanagement" element={<Eventmanagement />} />
          <Route exact path="/testing" element={<DropdownWithEditDelete />} />
          <Route exact path="/homeautomation" element={<HomeAutomation />} />
          <Route path='/userlisting/view/:id' element={<ViewUsers />} />
          <Route path='/channel/view/:id' element={<ChannelView />} />

          {/* <Route exact path="/testing" element={<div>Test Route</div>} /> */}

        </Route>
        <Route path="*" element={<WrongPath />} />
      </Routes>
    </>
  );
}